import React, { useEffect, useState } from 'react';
import { Modal,IconButton, Box, Typography, TextField, Button, DialogContent, Dialog, Select, MenuItem, SelectChangeEvent, DialogTitle } from '@mui/material';
import { InventoryDetails } from "src/models/Inventory";
import { InventoryService } from 'src/services/InventoryService';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import { InventoryCategories } from 'src/models/InventoryCategory';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';


export interface ProductProps {
    open: boolean;
    onClose: () => void;
    shopId: string;
  userId: string;
  inventory:any;
  setInventory:any
}
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const AddProduct: React.FC<ProductProps> = ({ open, onClose, shopId, userId,inventory,setInventory }) => {

    const [categories, setCategories] = useState<{ _id: string; categoryName: string }[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [newCategoryName, setNewCategoryName] = useState<string>('');
    const [categoryModalOpen, setCategoryModalOpen] = useState<boolean>(false);

    useEffect(() => {
        getAllCategories();
    }, [shopId]);


    const getAllCategories = () => {
      InventoryService.getCategories(shopId,"").then((res) => {
        if (res.success) {
           const categoryList = res.data.categories || [];
           setCategories(categoryList);
        }
      });
    };


    const handleSubmit = (values: any, { resetForm }) => {
      const data: InventoryDetails = {
        shopId: shopId,
        inventoryCategoryId: values.selectedCategory,
        createdBy: values.userId,
        productName: values.productName,
        sKUNumber: values.skuNumber,
        brandName: values.brandName,
        productPrice: values.productPrice,
        price: values.productSellingPrice,
        quantity: values.productQuantity
      };

      InventoryService.addProduct(data).then((res) => {
        if (res.success) {
          Swal.fire('Product Added Successfully');
          const updatedInvenotoryList = [res.data, ...inventory];
          setInventory(updatedInvenotoryList);
          resetForm();
          onClose();
        } else {
          onClose();
          Swal.fire({
            icon: 'error',
            title: 'Failed to Add Product',
            confirmButtonColor: '#FD7F00'
          });
        }
      });
    };

    const formik = useFormik({
      initialValues: {
        productName: '',
        skuNumber: '',
        brandName: '',
        productPrice: '',
        productSellingPrice: '',
        productQuantity: '',
        selectedCategory: ''
      },
      validationSchema: Yup.object({
        productName: Yup.string().required('Product Name is required'),
        skuNumber: Yup.string().required('SKU Number is required'),
        brandName: Yup.string().required('Brand Name is required'),
        productPrice: Yup.number()
        .required('Product Price is required')
        .positive('Price must be positive'),
        productSellingPrice: Yup.number()
        .required('Product Selling Price is required')
        .positive('Price must be positive'),
        productQuantity: Yup.number()
        .required('Product Quantity is required')
        .positive('Quantity must be positive')
        .integer('Quantity must be an integer'),
        selectedCategory: Yup.string().required('Category is required')
      }),
      onSubmit: handleSubmit
    });

    const handleAddCategory = () => {

        const data: InventoryCategories = {
            shopId: shopId,
            categoryName: newCategoryName,
            categoryColor: "64aa06",
            isPinCategory: false,
            createdBy: userId
        }

        InventoryService.createInventoryCategory(data).then(res => {
            if (res.success) {
              Swal.fire({
                  customClass: {
                  container: 'my-swal'
                   },
                  position: 'top-end',
                  icon: 'success',
                  title: 'New Category Added Successfully',
                  showConfirmButton: false,
                  timer: 1500
              });
              
                 const newCategory = {
                   _id: selectedCategory,
                   categoryName: newCategoryName
              };
              
                 setCategories([...categories, res.data]);
                 setSelectedCategory(newCategory._id);
                 setNewCategoryName('');
                 setCategoryModalOpen(false);
            } else {
               setNewCategoryName('');
               setCategoryModalOpen(false);
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: 'New Category Added Fail',
                  showConfirmButton: false,
                  timer: 1500
                });
            }
        })

       
    };

    const handleCategoryChange = (event: SelectChangeEvent<string>) => {
        if (event.target.value === 'add-new-category') {
            setCategoryModalOpen(true);
        } else {
          console.log("setcategory values",event.target.value)
            formik.setFieldValue('selectedCategory', event.target.value);
        }
    };
  const handleClose = () => {
    onClose();
  };
    return (
      <div>
        <Dialog
          onClose={onClose}
          open={open}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <Typography variant="h4" fontWeight="bold">
              Add a New Product
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Field
                  as={TextField}
                  fullWidth
                  label="Product Name"
                  margin="normal"
                  variant="outlined"
                  name="productName"
                  error={
                    formik.touched.productName &&
                    Boolean(formik.errors.productName)
                  }
                  helperText={
                    formik.touched.productName && formik.errors.productName
                  }
                />
                <Field
                  as={TextField}
                  fullWidth
                  label="SKU Number"
                  margin="normal"
                  variant="outlined"
                  name="skuNumber"
                  error={
                    formik.touched.skuNumber && Boolean(formik.errors.skuNumber)
                  }
                  helperText={
                    formik.touched.skuNumber && formik.errors.skuNumber
                  }
                />
                <Field
                  as={TextField}
                  fullWidth
                  label="Brand Name"
                  margin="normal"
                  variant="outlined"
                  name="brandName"
                  error={
                    formik.touched.brandName && Boolean(formik.errors.brandName)
                  }
                  helperText={
                    formik.touched.brandName && formik.errors.brandName
                  }
                />
                <Field
                  as={TextField}
                  fullWidth
                  label="Product Price"
                  margin="normal"
                  variant="outlined"
                  type="number"
                  name="productPrice"
                  error={
                    formik.touched.productPrice &&
                    Boolean(formik.errors.productPrice)
                  }
                  helperText={
                    formik.touched.productPrice && formik.errors.productPrice
                  }
                />
                <Field
                  as={TextField}
                  fullWidth
                  label="Selling Price"
                  margin="normal"
                  variant="outlined"
                  type="number"
                  name="productSellingPrice"
                  error={
                    formik.touched.productSellingPrice &&
                    Boolean(formik.errors.productSellingPrice)
                  }
                  helperText={
                    formik.touched.productSellingPrice && formik.errors.productSellingPrice
                  }
                />
                <Field
                  as={TextField}
                  fullWidth
                  label="Quantity"
                  margin="normal"
                  variant="outlined"
                  type="number"
                  name="productQuantity"
                  error={
                    formik.touched.productQuantity &&
                    Boolean(formik.errors.productQuantity)
                  }
                  helperText={
                    formik.touched.productQuantity &&
                    formik.errors.productQuantity
                  }
                />
                <Field
                  as={Select}
                  fullWidth
                  displayEmpty
                  name="selectedCategory"
                  value={formik.values.selectedCategory}
                  onChange={handleCategoryChange}
                  error={
                    formik.touched.selectedCategory &&
                    Boolean(formik.errors.selectedCategory)
                  }
                >
                  <MenuItem value="" disabled>
                    Select Category
                  </MenuItem>
                  {categories.map((category) => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.categoryName}
                    </MenuItem>
                  ))}
                  <MenuItem value="add-new-category">
                    <em>Add New Category</em>
                  </MenuItem>
                </Field>
                {formik.touched.selectedCategory &&
                  formik.errors.selectedCategory && (
                    <Typography variant="body2" color="error">
                      {formik.errors.selectedCategory}
                    </Typography>
                  )}
                <div style={{ height: 20 }}></div>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  fullWidth
                >
                  Submit
                </Button>
              </Form>
            </FormikProvider>
          </DialogContent>
        </Dialog>

        <Dialog
          open={categoryModalOpen}
          onClose={() => setCategoryModalOpen(false)}
        >
          <Box
            sx={{
              p: 2
            }}
          >
            <Typography variant="h6" component="h2">
              Add New Category
            </Typography>
            <TextField
              fullWidth
              label="Category Name"
              margin="normal"
              variant="outlined"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddCategory}
              sx={{ mt: 2 }}
            >
              Add Category
            </Button>
          </Box>
        </Dialog>
      </div>
    );
};

export default AddProduct;
