import React from 'react';
import {
  Box,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const Filter = ({
  billedValue,
  handelBilledAmout,
  value,
  startValue,
  endValue,
  showCustomDatePicker,
  handleChange,
  handleOpen,
  setStartValue,
  setEndValue,
  handleSearchChange,
  open,
  dropDownHeading,
  maxDate,
}) => {
    
  return (
    <>
      {/* <TextField
        select
        variant="outlined"
        label={dropDownHeading}
        value={billedValue}
        onChange={(event) => {
          const value = event.target.value;
          handelBilledAmout(value);
        }}
        SelectProps={{
          displayEmpty: true
        }}
        sx={{ minWidth: 100 }}
      >
        <MenuItem value="None">None</MenuItem>
        <MenuItem value="Low to high">Low to high</MenuItem>
        <MenuItem value="High to low">High to low</MenuItem>
      </TextField> */}

      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="date-filter-label">Date</InputLabel>
        <Select
          labelId="date-filter-label"
         // open={open}
        //  onOpen={handleOpen}
          value={value === '' ? startValue : value}
          label="date"
          onChange={handleChange}
        >
          <MenuItem value="All">
            <em>All</em>
          </MenuItem>
          <MenuItem value="Today">Today</MenuItem>
          <MenuItem value="Last 7 days">Last 7 days</MenuItem>
          <MenuItem value="Last 28 days">Last 28 days</MenuItem>
          <MenuItem value="Custom">Custom</MenuItem>
        </Select>
      </FormControl>

      {showCustomDatePicker && (
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <DatePicker
            label="Start Date"
            value={startValue}
            onChange={(newValue) => {
              setStartValue(newValue);
              handleSearchChange();
            }}
            maxDate={dayjs(maxDate)}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
          <DatePicker
            label="End Date"
            value={endValue}
            onChange={(newValue) => {
              setEndValue(newValue);
              handleSearchChange();
            }}
            maxDate={dayjs(maxDate)}
            renderInput={(params) => (
              <>
                <TextField {...params} />
              </>
            )}
          />
        </Box>
      )}
    </>
  );
};

export default Filter;
